import {
    Alert,
    CircularProgress,
} from "@mui/material";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import Typography from "@mui/material/Typography";
import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import {jediEquilibriumHealthQuery} from "../../clients/graphql/pages/trigger-type-tile-query";

export function TriggerTypeTile () {

    const capitalizeFirstLetter = (text) => {
        if (!text) return "";
        return text
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join('_')
    };

    const {loading, error, data} = useQueryCoordinate(jediEquilibriumHealthQuery, {});


    if (loading) {
        return <CircularProgress size={60} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto"}} />;
    }

    if (error) {
        return <Alert severity="warning">INTERNAL ERROR: error querying 'jediEquilibriumHealthQuery'</Alert>;
    }

    let tableData = data?.jediEquilibriumHealthQuery?.stats;

    const columns = [
        {
            field: 'id',
            headerName: 'Type',
            width: 195,
            valueFormatter: (value) => {
                return value ? capitalizeFirstLetter(value) : '';
            }
        },
        {
            field: 'todaysP50',
            headerName: "Today's P50",
            width: 120,
        },
        {
            field: 'todaysP90',
            headerName: "Today's P90",
            width: 120,
            renderCell: (params) => {
                const value = params.value;
                return value !== null && value !== undefined
                    ? value.toFixed(2)
                    : '';
            },
        },
        {
            field: 'lastWeekP50',
            headerName: "Last Week's P50",
            width: 140,
        },
        {
            field: 'lastWeekP90',
            headerName: "Last Week's P90",
            width: 140,
            renderCell: (params) => {
                const value = params.value;
                return value !== null && value !== undefined
                    ? value.toFixed(2)
                    : '';
            },
        }
    ];

    const rows = tableData.map(row => {
        return {
            id: row.type,
            todaysP50: row.todaysP50,
            lastWeekP50: row.lastWeekP50,
            todaysP90: row.todaysP90,
            lastWeekP90: row.lastWeekP90,
        };
    });
    return (

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingX: '1rem',
                height: '22rem',
                paddingY: '1rem',
            }}>
                <Typography variant="subtitle1" align="left"
                                        sx={{
                                            color: '#6D6D6D',
                                            font: 'Roboto',
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            lineHeight: '12px',
                                            letterSpacing: '0.05em',
                                            paddingBottom: '.75rem',
                                        }}>
                    TRIGGER TYPE HEALTH
                </Typography>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    rowHeight={40}
                    hideFooter={true}
                    pageSizeOptions={[5]}
                />
            </Box>
    )
}