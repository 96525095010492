import React from 'react';
import {DataGrid, GridRenderCellParams} from '@mui/x-data-grid';
import {CircularProgress, Tooltip, Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import {useNavigate} from 'react-router-dom';
import {useQueryAsUser} from '../../clients/graphql/graphql.hooks';
import _ from 'lodash';
import '../../App.css';
import moment from 'moment';
import Box from "@mui/material/Box";
import {listPilotsQuery, PilotData} from "../../clients/graphql/components/tables-queries";

export interface PilotListData {
    autoPilotSettingsKey: number;
    pilot: string;
    allocation: number;
    status: string;
    portfolio: string;
    amountPending: number;
}

export const PilotListTable: React.FC<{ userKey: number }> = ({ userKey}) => {
    const navigate = useNavigate();
    const { data, error, loading } = useQueryAsUser(listPilotsQuery, { userKey, variables: { userKey } });
    if (loading) {
        return <CircularProgress size={60} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", marginTop: "30px", marginLeft: '50%'}} />
    }
    if (error) {
        return <div>Error fetching data. Please reload page</div>;
    }
    
    const pilots = data?.autoPilotSettingsGet?.autoPilotSettings ?? [];
    const unsortedListData = pilots.map((pilot: PilotData) => ({
        id: pilot.autoPilotSettingsKey,
        autoPilotSettingsKey: pilot.autoPilotSettingsKey,
        pilot: pilot.masterPortfolio.autoPilotMasterPortfolio?.title ?? `${pilot.masterUser?.firstName} ${pilot.masterUser?.lastName}`,
        allocation: pilot.allocationAmount,
        status: pilot.setupStatus,
        portfolio: pilot.slavePortfolio?.brokerName,
        executionType: pilot.executionType,
        amountPending: pilot.amountPending,
        initiatedAt: pilot.initiatedAt,
        cardUrl: pilot.masterPortfolio.autoPilotMasterPortfolio?.profileImageUrl,
    }));
    
    const statusOrder = ['SHORT_CIRCUITED', 'PAUSED', 'ACTIVE', 'INITIATED', 'DELETED'];
    const listData = _.sortBy(unsortedListData, item => statusOrder.indexOf(item.status));
    
    const handleRowClick = (settingsKey: number) => {
        navigate(`/pilot-details/${settingsKey}`);
    };
    
    const columns = [
        {
            field: 'pilot',
            headerName: 'Pilot',
            flex: 1.3,
            renderCell: (params: GridRenderCellParams) => {
                const imageUrl = params.row.cardUrl;

                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="Profile"
                                style={{
                                    width: '32px',
                                    height: '32px',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    marginRight: '10px',
                                }}
                            />
                        ) : (
                            <PersonIcon style={{
                                marginRight: '10px',
                                width: '32px',
                                height: '32px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                            }} />
                        )}
                        <span style={{marginLeft: '10px'}}>{params.value}</span>
                    </div>
                );
            }
        },
        {
            field: 'allocation',
            headerName: 'Allocation',
            flex: 0.7,
            renderCell: (params: GridRenderCellParams) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {params.row.allocation?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    {(params.row.amountPending !== 0) && (
                        <Tooltip title={`There is currently an amount pending of $${params.row.amountPending}`}>
                            <InfoIcon style={{ marginLeft: '5px'}} color={'error'}/>
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1.1,
            renderCell: (params: GridRenderCellParams) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {params.value === 'DELETED' && <DeleteOutlinedIcon style={{ color: 'orange' }} />}
                    {params.value === 'ACTIVE' && <CheckCircleOutlinedIcon color={'success'} />}
                    {params.value === 'SHORT_CIRCUITED' && <ErrorOutlinedIcon color={'error'} />}
                    <span style={{ marginLeft: '5px' }}>{params.value}</span>
                </div>
            ),
        },
        {
            field: 'initiatedAt',
            headerName: 'Started At',
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    {moment(params.value).format('MM/DD/YYYY')}
                </div>
            ),
            flex: 1,
        },
        {
            field: 'portfolio',
            headerName: 'Portfolio',
            flex: 1,
        },
        {
            field: 'executionType',
            headerName: 'Execution Type',
            flex: 1,
        },
    ];
    
    return (

        <Box sx={{ display: 'flex', flexDirection: 'column', height: '27rem', paddingY: '1rem'}}>
            <Typography variant="subtitle1" component="div" align="left"
                        sx={{
                            color: '#6D6D6D',
                            font: 'Roboto',
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '12px',
                            letterSpacing: '0.05em',
                            paddingBottom: '.75rem',
                        }}>
                PILOTS
            </Typography>
            <DataGrid
                rows={listData}
                columns={columns}
                loading={loading}
                rowHeight={40}
                rowCount={listData.length}
                onRowClick={(params) => handleRowClick(params.row.autoPilotSettingsKey)}
                hideFooter={true}
            />
        </Box>
    );
};