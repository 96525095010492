import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Alert, CircularProgress, Grid, styled} from "@mui/material";
import InstitutionCrudPanel from "../../views/institutions/InstitutionCrudPanel";
import {useParams} from "react-router-dom";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import {InstitutionPortfoliosList} from "../../views/institutions/InstitutionPortfoliosList";
import {InstitutionAuthLogsTable} from "../../components/tables/InstitutionAuthLogsTable";
import {InstitutionTimestampsPanel} from "../../views/institutions/InstitutionTimestampsPanel";
import {getInstitutionDetails,} from "../../clients/graphql/pages/users-queries";
import {RefreshAuthTokenButton} from "../../components/buttons/RefreshAuthTokenButton";
import {DisconnectInstitutionButton} from "../../components/buttons/DisconnectInstitutionButton";

const Item = styled(Box)(({ theme }) => ({
    border: '1px solid rgba(18, 18, 18, 0.15)',
    borderRadius: '5px',
    marginTop: 10,
    marginBottom: 10,
    overflow: 'hidden',
}));

export function UserInstitutionPage() {
    const { institutionKey  } = useParams();
    const intInstitutionKey = parseInt(institutionKey as string);

    const {loading, error, data} = useQueryCoordinate(getInstitutionDetails, {variables: {institutionKey: intInstitutionKey}});
    const curatedData = data?.whoisit?.institution;

    if (loading) {
        return <CircularProgress sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto"}}/>
    }

    if (error) {
        return <Alert severity="warning">{error?.message}</Alert>
    }

    return (
        <Box>
            <Typography variant={"h4"} paddingBottom={2}>Institution&nbsp;
                <span style={{color: 'grey'}}>
                    #{institutionKey}
                </span></Typography>
            <Box display={"flex"} gap={1}>
                <RefreshAuthTokenButton userKey={data?.whoisit?.institution?.userKey} institutionKey={intInstitutionKey}/>
                <DisconnectInstitutionButton userKey={parseInt(data?.whoisit?.institution?.userKey)} institutionKey={intInstitutionKey}/>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Item>
                        <InstitutionCrudPanel institutionKey={intInstitutionKey} userKey={data?.whoisit?.institution?.userKey}/>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <InstitutionPortfoliosList portfolios={curatedData?.portfolios}/>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        <InstitutionTimestampsPanel institutionKey={intInstitutionKey}/>
                    </Item>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{height: 500}}>
                <Grid item xs={8}>
                    <Item>
                        <InstitutionAuthLogsTable institutionKey={intInstitutionKey}/>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    )
}