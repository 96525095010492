import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {SetStateAction, useState} from "react";
import {InputAdornment} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function AssetSearchBox() {

    const [tickerSymbol, setTickerSymbol] = useState('');

    const handleInputChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setTickerSymbol(e.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (tickerSymbol.trim() !== '') {
            window.location.href = `${tickerSymbol}`;
        }
    };

    return(
        <>
            <Box
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                    }}
                >

                <form onSubmit={handleSubmit}>
                    <TextField
                    label="Search Stock Ticker Symbol"
                    variant="outlined"
                    value={tickerSymbol}
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        width: '20rem',
                    }}
                />
                </form>
            </Box>
        </>
    );
}