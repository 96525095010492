import {Modal} from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {SetStateAction, useState} from "react";

export default function AssetSearchModum({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {

    const [tickerSymbol, setTickerSymbol] = useState('');

    const handleInputChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setTickerSymbol(e.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (tickerSymbol.trim() !== '') {
            window.location.href = `${tickerSymbol}`;
            onClose();
        }
    };

    return(
        <>
            <Modal
                open={isOpen}
                onClose={onClose}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        p: 1,
                        outline: 0,
                    }}
                >
                    <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        autoFocus={true}
                        label="Enter Stock Ticker Symbol"
                        variant="outlined"
                        value={tickerSymbol}
                        onChange={handleInputChange}
                        sx={{ my: 2 }}
                    />
                    </form>
                </Box>
            </Modal>
        </>
    );
}