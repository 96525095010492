import {gql} from "@apollo/client";
import { BROKER_NAME, CONNECTION_STATUS } from '../../../shared/portfolio-types';

export const jediAllPilotsQuery = gql`
    query{
      jediAllPilots {
        pilots{
          userKey
          portfolioKey
          title
          description
          sourceDescription
          profileImageUrl
          profileImageLargeUrl
          alternateProfileImageLargeUrl
          cardImageUrl
          subtitle
          tradeDelayTime
          yearlyAverage
          currentYear
          previousYear
          yearsTrading
          nbrTradesPerMonth
          maxCashPositionAmount
          priority
          remoteGhostSlug
        }
      }
    }`;

export interface AllPilotsData {
    userKey: number;
    portfolioKey: number;
    title: string;
    description: string;
    sourceDescription: string;
    profileImageUrl: string;
    profileImageLargeUrl: string;
    alternateProfileImageLargeUrl: string;
    cardImageUrl: string;
    subtitle: string;
    tradeDelayTime: string;
    yearlyAverage: number;
    currentYear: number;
    previousYear: number;
    yearsTrading: number;
    nbrTradesPerMonth: number;
    maxCashPositionAmount: number;
    priority: number;
    remoteGhostSlug: string;
}

export const getAutopilotCash = gql`
    query slaveAutoPilotPortfoliosGet ($autoPilotSettingsKey: Int!){
        slaveAutoPilotPortfoliosGet( 
            input:{
            autoPilotSettingsKey: $autoPilotSettingsKey,
        }
    ) {
            slaveAutoPilotPortfolios {
                autoPilotCashHolding {
                    marketValue
                    percentOfPortfolio
                }
            }
        }
    }`;

export const jediFailedAutopilotOrdersGet = gql`
    query jediFailedAutopilotOrdersGet(
        $userKey: Int
        $portfolioKey: Int
        $autoPilotSettingsKey: Int
        $limit: Int!
        $offset: Int!
    ) {
        jediFailedAutopilotOrdersGet(input: {
            userKey: $userKey
            portfolioKey: $portfolioKey
            autoPilotSettingsKey: $autoPilotSettingsKey
            limit: $limit
            offset: $offset
        }) {
            autoPilotOrders {
                asset {
                    pictureUrl
                }
                symbol
                slavePortfolioKey
                orderSide
                quantity
                averagePrice
                createdAt
                failureReason
            }
        }
    }
`;

export interface JediFailedAutopilotOrdersResult {
    jediFailedAutopilotOrdersGet: {
        autoPilotOrders: JediFailedAutopilotOrder[];
    };
}

export interface JediFailedAutopilotOrder {
    asset: {
        pictureUrl: string;
    };
    symbol: string;
    slavePortfolioKey: string;
    orderSide: string;
    quantity: number;
    averagePrice: number;
    createdAt: string;
    failureReason: string;
}


export const institutionAuthActivitiesGet = gql`
    query institutionAuthActivitiesGet(
        $institutionKey: Int!
        $limit: Int!
        $offset: Int!
    ) {
        institutionAuthActivitiesGet(input: {
            institutionKey: $institutionKey
            limit: $limit
            offset: $offset
        }) {
            institutionAuthActivities {
                activityType
                createdAt
                note
                success
                data
            }
        }
    }
`;

export interface institutionAuthActivity {
    activityType: string;
    createdAt: string;
    note: string;
    success: boolean;
    data: any;
};

export const listPilotsQuery = gql`
    query autoPilotSettingsGet {
        autoPilotSettingsGet(input: { setupStatuses: [DELETED, INITIATED, ACTIVE, PAUSED, SHORT_CIRCUITED] }) {
            autoPilotSettings {
                autoPilotSettingsKey
                setupStatus
                slavePortfolio {
                    portfolioName
                    brokerName
                    connectionStatus
                }
                masterUser {
                    firstName
                    lastName
                }
                masterPortfolio{
                  autoPilotMasterPortfolio{
                    title
                    profileImageUrl
                  }
                }
                allocationAmount
                amountPending
                initiatedAt
                deletedAt
                executionType    
            }
        }
    }
`;

export interface PilotData {
    autoPilotSettingsKey: number;
    setupStatus: string;
    deletedAt: Date;
    initiatedAt: Date;
    slavePortfolio: {
        portfolioName: string;
        brokerName: BROKER_NAME;
        connectionStatus: CONNECTION_STATUS;
    };
    masterUser: {
        firstName: string;
        lastName: string;
    }
    masterPortfolio: {
        autoPilotMasterPortfolio: {
            title: string;
            profileImageUrl: string;
        }
    };
    allocationAmount: number;
    amountPending: number;
    executionType: string
}

export const portfolioEventsGetQuery = gql`
    query portfolioEventsGet(
        $portfolioKey: Int! ){ 
        portfolioEventsGet( input: { portfolioKey: $portfolioKey } ) {
            portfolioEvents {
                portfolioEventKey
                status
                type
                portfolioKey
                amount
                createdAt
                reason
                autoPilotSettingsKey
            }
        }
    }`;

export interface PortfolioEvent{
    status: string,
    portfolioEventKey: string,
    type: string,
    autoPilotSettingsKey: string,
    reason: string,
    amount: string,
    createdAt: string
}



export const jediPortfolioPositionsGet = gql`
    query jediPortfolioPositionsGet(
        $portfolioKey: Int!
    ) {
        jediPortfolioPositionsGet(input: {
            portfolioKey: $portfolioKey
        }) {
            positions {
                portfolioKey
                portfolio {
                    brokerName
                    connectionStatus
                }
                symbol
                remotePosition {
                    averagePrice
                    quantity
                }
                localPosition {
                    averagePrice
                    quantity
                }
                manualPosition {
                    averagePrice
                    quantity
                }
                pilotedPositions {
                    averagePrice
                    quantity
                    pilotName
                    autoPilotSettingsKey
                }
            }
        }
    }
`;

export interface JediPortfolioPositionsGetResponse {
    jediPortfolioPositionsGet: {
        positions: JediPortfolioPosition[];
    };
};

export interface JediPortfolioPosition {
    portfolioKey: string;
    portfolio: {
        brokerName: BROKER_NAME;
        connectionStatus: CONNECTION_STATUS;
    };
    symbol: string;
    remotePosition: {
        averagePrice: number;
        quantity: number;
    };
    localPosition: JediIndividualPosition;
    manualPosition: JediIndividualPosition;
    pilotedPositions: JediIndividualPosition[];
};

export interface JediIndividualPosition {
    averagePrice: number;
    quantity: number;
    pilotName: string;
    autoPilotSettingsKey: string;
};


export const jediPortfolioRestrictionsGet = gql`
    query portfolio(
        $portfolioKey: Int!
    ) {
        portfolioGet(input:{
            portfolioKey: $portfolioKey
        }) {
            portfolio {
                restrictions {
                    createdAt
                    reason
                    asset {
                        symbol
                        pictureUrl
                    }
                }
            }
        }
    }
`;
export type PortfolioRestriction = {
    asset: {
        symbol: any;
        pictureUrl: string;
    };
    createdAt: any;
    reason: any;
};

export const pilotTriggerHistoryQuery = gql`
    query triggerHistoryGet(
        $userKey: Int!
        $autoPilotSettingsKey: Int
        $limit: Int!
        $offset: Int!
    ) {
        triggerHistoryGet(input: {
            userKey: $userKey
            autoPilotSettingsKey: $autoPilotSettingsKey
            limit: $limit
            offset: $offset
        }) {
            triggerHistoryKey
            type
            transactionAmount
            autoPilotSettingsKey
            tradeCount
            createdAt
            title
            triggerStatus
            triggerApprovalStatus
            equilibriumAchievedAt
            approvedAt
            lastSuppressedAt
            lastSuppressionReason
            lastPendingNextCycleAt
            masterPortfolio {
                portfolioName
            }
            triggerHistoryOrders {
                asset {
                    assetKey
                    pictureUrl
                }
                symbol
                orderSide
                quantity
                averagePrice
                orderState
                orderStatus
                fullfilledAt
                transactionAmount
                failureReason
            }
        }
    }
`;

export const jediAllocationAdjustmentHistoryGet = gql`
    query($autoPilotSettingsKey: Int
          $userKey: Int)
      {
      jediAllocationAdjustmentHistoryGet(input: {autoPilotSettingsKey: $autoPilotSettingsKey, userKey: $userKey}) {
        allocationAdjustments{
          autoPilotSettingsKey
          autoPilotSettingsAllocationAdjustmentKey
          oldAllocationAmount
          newAllocationAmount
          amountDeficient
          createdAt
        }
      }
    }
`;

export const triggerHistoryOrdersQuery = gql`
  query triggerHistoryOrdersGet(
        $autoPilotSettingsKey: Int!, 
        $triggerHistoryKey: Int!
    ) {
        triggerHistoryOrdersGet (
            input: {
            autoPilotSettingsKey: $autoPilotSettingsKey
            triggerHistoryKey: $triggerHistoryKey
        }
    ) {
            asset {
                assetKey
                pictureUrl
            }
            symbol
            orderSide
            quantity
            averagePrice
            orderState
            orderStatus
            fullfilledAt
            transactionAmount
            failureReason
        }
    }
`;

export interface TriggerHistoryItem {
    triggerHistoryKey: number;
    type: string;
    transactionAmount: number;
    tradeCount: number;
    createdAt: Date;
    title: string;
    triggerStatus: string;
    triggerApprovalStatus: string;
    masterPortfolio: {
        portfolioName: string;
    } | null;
    triggerHistoryOrders: TriggerHistoryOrdersItem[] | null;
    typeColor: string;
    equilibriumAchievedAt: string;
    lastSuppressedAt: string;
    lastSuppressionReason: string;
    lastPendingNextCycleAt: string;
}

export interface AllocationAmountItem {
    autoPilotSettingsKey: number;
    autoPilotSettingsAllocationAdjustmentKey: string;
    oldAllocationAmount: number;
    newAllocationAmount: number;
    amountDeficient: number;
    createdAt: Date;
}

export interface TriggerHistoryOrdersItem {
    asset: {
        assetKey: number;
        pictureUrl: string;
    };
    symbol: string;
    orderSide: string;
    quantity: number;
    averagePrice: number;
    orderState: string;
    orderStatus: string;
    fullfilledAt: string;
    transactionAmount: number;
    failureReason: string;
}

export interface CustomTriggerHistoryOrdersItem {
    asset: {
        assetKey: number;
        pictureUrl: string;
    };
    symbol: string;
    orderSide: string;
    quantity: number;
    averagePrice: number;
    orderState: string;
    orderStatus: string;
    fullfilledAt: string;
    transactionAmount: number;
    failureReason: string;
    createdAt: Date;
}

export interface CurrentPosition {
    symbol: string;
    name: string;
    pictureUrl: string;
    quantity: number;
    percentOfPortfolio: number;
    marketValue: number;
    currentPrice: number;
    assetKey: string;
    unrealizedProfitLossPercent: number
    unrealizedProfitLoss: number
    assetDescription: string
}

export const jediResolveOverAllocation = gql`
    mutation jediResolveOverAllocation(
        $autoPilotSettingsKey: Int!
    ) {
        jediResolveOverAllocation(input: {
            autoPilotSettingsKey: $autoPilotSettingsKey
        }) {
            success
        }
    }
`;