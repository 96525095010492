import {Alert, Button} from "@mui/material";
import ConfirmAlertDialog from "../dialogs/confirm-alert";
import React from "react";
import {useMutationAsUser} from "../../clients/graphql/graphql.hooks";
import {disconnectInstitutionMutation} from "../../clients/graphql/pages/users-queries";

export interface ConfirmAlertDialogButtonProps {
    userKey: number;
    institutionKey: number;
}

export function DisconnectInstitutionButton({userKey, institutionKey} : ConfirmAlertDialogButtonProps ) {

    const [jediDisconnectInstitutionMutation, jediDisconnectInstitutionMutationState] = useMutationAsUser(disconnectInstitutionMutation, {variables: {institutionKey: institutionKey, userKey: userKey}})

    if (jediDisconnectInstitutionMutationState.error) {
        return <Alert severity="warning">{jediDisconnectInstitutionMutationState.error?.message}</Alert>
    }

    return(
        <ConfirmAlertDialog
            title='Are you sure you want to disconnect?'
            description='Disconnecting institution will disconnect ALL portfolios as well'
            actionButtonTitle='Disconnect Institution'
            onConfirm={() => {
                jediDisconnectInstitutionMutation()
            }}
            buttonComponent={
                <Button
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="outlined"
                    color="error"
                    size={'small'}
                >
                    Disconnect Institution
                </Button>
            }
        />
    );
}