import {gql} from "@apollo/client";

export const jediStatusBreakdownQuery = gql`
    query{
      jediStatusBreakdownQuery{
        data{
          brokerName,
          inEquilibrium,
          pendingNextCycle,
          suppressed,
          unknownState,
          shortCircuited,
          total,
          percentInEquilibrium,
          percentPendingNextCycle,
          percentSuppressed,
          percentShortCircuited,
          percentUnknownState,
          topReasons
        }
      }
    }`;