import {useTheme} from "@mui/material/styles";
import {Alert, CircularProgress, Dialog, Grid, styled} from "@mui/material";
import Box from "@mui/material/Box";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import {jediStatusBreakdownQuery} from "../../clients/graphql/pages/home-queries";
import {useState} from "react";
import ToolTip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import {DataGrid} from "@mui/x-data-grid";
import {TriggerTypeTile} from "./TriggerTypeTile";
import {PieChart} from "@mui/x-charts/PieChart";
export default function Home () {

    const theme = useTheme();

    const capitalizeFirstLetter = (text) => {
        if (!text) return "";
        return text
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join('_')
    };

    const Item = styled(Box)(({ theme }) => ({
        border: '1px solid rgba(18, 18, 18, 0.15)',
        borderRadius: '5px',
        marginTop: 10,
        overflow: 'hidden',
    }));

    const {loading, error, data} = useQueryCoordinate(jediStatusBreakdownQuery, {});

    let tableData = data?.jediStatusBreakdownQuery?.data
    const [selectedType, setSelectedType] = useState(-1);

    let totalEquilibrium = 0;
    let totalPending = 0;
    let totalSuppressed = 0;
    let totalUnknownState = 0;

    if (loading) {
        return <CircularProgress size={60} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto"}} />;
    }

    tableData.forEach(row => {
        totalEquilibrium += row.inEquilibrium;
        totalPending += row.pendingNextCycle;
        totalSuppressed += row.suppressed;
        totalUnknownState += row.unknownState;
    })

    if (error) {
        return <Alert severity="warning">INTERNAL ERROR: error querying 'jediStatusBreakdownQuery'</Alert>;
    }

    function joinArrayWithCommas(arr) {
        return arr.join(', ');
    }

    const columns = [
        { field: 'id',
            headerName: 'Broker Name',
            width: 160,
            valueFormatter: (value) => {
                return capitalizeFirstLetter(value);
            }
        },
        {
            field: 'inEquilibrium',
            headerName: 'In Equilibrium',
            width: 130,
            valueFormatter: (value) => {
                const number = value.match(/(\d+) /g);
                const percent = value.match(/\(.*\)/g);
                return parseInt(number).toLocaleString() + ' ' + percent;
            }
        },
        {
            field: 'pendingNextCycle',
            headerName: 'Pending Next Cycle',
            width: 165,
            valueFormatter: (value) => {
                const number = value.match(/(\d+) /g);
                const percent = value.match(/\(.*\)/g);
                return parseInt(number).toLocaleString() + ' ' + percent;
            }
        },
        {
            field: 'suppressed',
            headerName: 'Suppressed',
            width: 120,
            valueFormatter: (value) => {
                const number = value.match(/(\d+) /g);
                const percent = value.match(/\(.*\)/g);
                return parseInt(number).toLocaleString() + ' ' + percent;
            },
        },
        {
            field: 'unknownState',
            headerName: 'Unknown State',
            width: 140,
            valueFormatter: (value) => {
                const number = value.match(/(\d+) /g);
                const percent = value.match(/\(.*\)/g);
                return parseInt(number).toLocaleString() + ' ' + percent;
            },
        },
        {
            field: 'shortCircuited',
            headerName: 'Short Circuited',
            width: 140,
            valueFormatter: (value) => {
                const number = value.match(/(\d+) /g);
                const percent = value.match(/\(.*\)/g);
                return parseInt(number).toLocaleString() + ' ' + percent;
            },
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 70,
            valueFormatter: (value) => {
                return parseInt(value).toLocaleString();
            },
        },
        {
            field: 'topReasons',
            headerName: 'Top Reasons',
            renderCell: (params) => {
                return(
                    <ToolTip title={joinArrayWithCommas(params.row.topReasons)}>
                        {params.row.topReasons.length === 1 ? 'none' : <InfoIcon style={{verticalAlign: "middle"}} />}
                    </ToolTip>
                )
            },
            width: 130,
        },
    ];

    const rows = tableData.map(row => {
        return {
            id: row.brokerName,
            inEquilibrium: row.inEquilibrium + ' (' + (row.percentInEquilibrium * 100).toPrecision(2) + '%)',
            pendingNextCycle: row.pendingNextCycle + ' (' + (row.percentPendingNextCycle * 100).toPrecision(2) + '%)',
            shortCircuited: row.shortCircuited + ' (' + (row.percentShortCircuited * 100).toPrecision(2) + '%)',
            suppressed: row.suppressed + ' (' + (row.percentSuppressed * 100).toPrecision(2) + '%)',
            total: row.total,
            unknownState: row.unknownState + ' (' + (row.percentUnknownState * 100).toPrecision(2) + '%)',
            topReasons: row.topReasons
        };
    });

    const institutionHealthData = [
        {
            id: 0,
            value: totalEquilibrium,
            label: 'Total Equilibrium',
            color: theme.palette.success.light,
        },
        {   id: 1,
            value: totalPending,
            label: 'Total Pending',
            color: theme.palette.primary.light,
        },
        {
            id: 2,
            value: totalSuppressed,
            label: 'Total Suppressed',
            color: theme.palette.warning.light,
        },
        {
            id: 3,
            value: totalUnknownState,
            label: 'Total Unknown State',
            color: "#b0b0b0",
        },
    ]

    function getColumn(type) {
        return ([
            {
                field: 'id',
                headerName: 'Broker',
                width: 160,
                valueFormatter: (params) => {
                    return capitalizeFirstLetter(params);
                },
            },
            {
                field: 'data',
                headerName: institutionHealthData[type].label,
                width: 160,
                valueFormatter: (params) => {
                    return params ? params.toLocaleString() : '';
                },
            }
        ]);
    }

    function getRows(type){
        let data = null
        return tableData.map(row => {
            switch (type) {
                case 0:
                    data = row.inEquilibrium;
                    break;
                case 1:
                    data = row.pendingNextCycle;
                    break;
                case 2:
                    data = row.suppressed;
                    break;
                case 3:
                    data = row.unknownState;
                    break;
                default:
                    data = 'null';
            }
            return {
                id: row.brokerName,
                data: data,
            };
        });
    }


    return (
        <>
            <Grid containerSpacing={2}>
                <Grid item xs={12}>
                    <Item marginBottom={'1rem'}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingX: '1rem',
                            height: '24.6rem',
                            paddingY: '1rem',
                        }}>
                            <Typography variant="subtitle1" align="left"
                                        sx={{
                                            color: '#6D6D6D',
                                            font: 'Roboto',
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            lineHeight: '12px',
                                            letterSpacing: '0.05em',
                                            paddingBottom: '.75rem',
                                        }}>
                                INSTITUTION HEALTH
                            </Typography>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                rowHeight={40}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 8,
                                        },
                                    },
                                    sorting: {
                                        sortModel: [{field: 'total', sort: 'desc'}],
                                    },
                                }}
                                pageSizeOptions={[8]}
                                hideFooter={true}
                            />
                        </Box>
                    </Item>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Item>
                            <TriggerTypeTile/>
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '22rem',
                                paddingY: '1rem',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}><PieChart
                                series={[
                                    {
                                        data: institutionHealthData,
                                        cx: "31%",
                                        // innerRadius: 30,
                                        // paddingAngle: 5,
                                        // cornerRadius: 5,
                                        // startAngle: 0,
                                        // endAngle: 360,
                                        highlightScope: {faded: 'global', highlighted: 'item'},
                                        faded: {
                                            innerRadius: 30,
                                            additionalRadius: -30,
                                            color: 'gray'
                                        },
                                        id: 'testing',

                                    },
                                ]}
                                onItemClick={(event, d) => setSelectedType(d.dataIndex)}
                                height={200}
                                width={400}
                            /></Box>
                        </Item>
                    </Grid>
                </Grid>

                {selectedType !== -1 && (
                    <Dialog
                        open={selectedType !== -1}
                        onClose={() => setSelectedType(-1)}
                        PaperProps={{
                            style: {
                                maxHeight: '30rem',
                                overflow: 'auto',
                            },
                        }}
                    >
                        <DataGrid
                            columns={getColumn(selectedType)}
                            rows={getRows(selectedType)}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 8,
                                    }
                                },
                                sorting: {
                                    sortModel: [{ field: 'data', sort: 'desc' }],
                                },
                            }}
                            pageSizeOptions={[8]}
                            sx={{width: "100%"}}
                            hideFooter={true}
                        />

                    </Dialog>
                )}
            </Grid>
        </>)
}