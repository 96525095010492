import {Typography, CircularProgress} from "@mui/material";
import {DataGrid, GridRenderCellParams} from "@mui/x-data-grid";
import React from "react";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import {AllPilotsData, jediAllPilotsQuery} from "../../clients/graphql/components/tables-queries";
import Box from "@mui/material/Box";

export const AllPilotsTable = () => {

    const {loading, error, data} = useQueryCoordinate(jediAllPilotsQuery, {});
    const pilots = data?.jediAllPilots?.pilots;

    if (loading) {
        return <CircularProgress size={60} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto"}} />;
    }
    if (error) {
        return <div>Error fetching data. Please reload page</div>;
    }

    const columns = [
        {
            field: 'title',
            headerName: 'Pilot',
            width: 250,
        },

        {
            field: 'tradeDelayTime',
            headerName: 'Trade Delay',
            width: 100,
        },

        {
            field: 'currentYear',
            headerName: 'Current Year',
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                if (params.value == null) {
                    return ''; // You can also use '0%' or any other placeholder text.
                }

                const value = params.value * 100;

                return (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
                        <Typography fontSize={'small'} style={{ color: value >= 0 ? 'green' : 'red' }}>
                            {value.toFixed(2)}%
                        </Typography></div>
                )
            }
        },
        {
            field: 'previousYear',
            headerName: 'Previous Year',
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                if (params.value == null) {
                    return ''; // You can also use '0%' or any other placeholder text.
                }

                const value = params.value * 100;

                return (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
                        <Typography fontSize={'small'} style={{ color: value >= 0 ? 'green' : 'red' }}>
                            {value.toFixed(2)}%
                        </Typography></div>
                )
            }
        },
    ];

    const rows = pilots.map((row: AllPilotsData, index: number) => {
        return {
            id: index,
            ...row,
        }
    });

    return (
        <Box>
            <Typography variant="subtitle1" component="div" align="left"
                        sx={{
                            color: '#6D6D6D',
                            font: 'Roboto',
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '12px',
                            letterSpacing: '0.05em',
                            paddingBottom: '.75rem',
                        }}>
                PILOTS
            </Typography>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    rowHeight={40}
                    hideFooter={true}
                    sx={{
                        height: '40rem'
                    }}
                />
        </Box>
    );
}