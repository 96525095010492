import {Alert, Button} from "@mui/material";
import React from "react";
import {useMutationAsUser} from "../../clients/graphql/graphql.hooks";
import {refreshAuthTokenMutation} from "../../clients/graphql/pages/users-queries";

export interface RefreshAuthTokenProps {
    userKey: number;
    institutionKey: number;
}

export function RefreshAuthTokenButton({userKey, institutionKey}: RefreshAuthTokenProps) {

    const [jediRefreshAuthTokenMutation, jediRefreshAuthTokenState] = useMutationAsUser(refreshAuthTokenMutation, { userKey: userKey, variables: {institutionKey: institutionKey}});

    if (jediRefreshAuthTokenState.error) {
        return <Alert severity="warning">{jediRefreshAuthTokenState.error?.message}</Alert>
    }

    return(
        <Button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            color="primary"
            size={'small'}
            onClick={() => {
                jediRefreshAuthTokenMutation()
            }}
        >
            Refresh Auth Token
        </Button>
    );
}